// PESTICIDE
export const GET_PESTICIDES = "GET_PESTICIDES";
export const GET_PESTICIDES_SUCCESS = "GET_PESTICIDES_SUCCESS";
export const GET_PESTICIDES_FAIL = "GET_PESTICIDES_FAIL";

export const GET_PESTICIDE_BY_ID = "GET_PESTICIDE_BY_ID";
export const GET_PESTICIDE_BY_ID_SUCCESS = "GET_PESTICIDE_BY_ID_SUCCESS";

export const ADD_NEW_PESTICIDE = "ADD_NEW_PESTICIDE";

export const UPDATE_PESTICIDE = "UPDATE_PESTICIDE";

export const DELETE_PESTICIDE = "DELETE_PESTICIDE";

export const PESTICIDE_CLEAR_ERROR = "PESTICIDE_CLEAR_ERROR";
