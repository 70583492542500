import {
    GET_BORDER_CROSSINGS, 
    GET_BORDER_CROSSINGS_SUCCESS,
    GET_BORDER_CROSSINGS_FAIL,
      GET_BORDER_CROSSING_BY_ID,
      GET_BORDER_CROSSING_BY_ID_SUCCESS,
      ADD_NEW_BORDER_CROSSING,
      UPDATE_BORDER_CROSSING,
      DELETE_BORDER_CROSSING,
      BORDER_CROSSING_CLEAR_ERROR,
    } from "./actionTypes";
    
    export const getBorderCrossings = query => ({  
      type: GET_BORDER_CROSSINGS,
      payload: query,
    });
    
    export const getBorderCrossingsSuccess = banners => ({
      type: GET_BORDER_CROSSINGS_SUCCESS,
      payload: banners,
    });
      
    export const getBorderCrossingsFail = error => ({
      type: GET_BORDER_CROSSINGS_FAIL,
      payload: error,
    });
    
    export const getBorderCrossingById = id => ({
      type: GET_BORDER_CROSSING_BY_ID,
      payload: id,
    });
    
    export const getBorderCrossingByIdSuccess = banner => ({
      type: GET_BORDER_CROSSING_BY_ID_SUCCESS,
      payload: banner,
    });
    
    export const getBorderCrossingByIdFail = error => ({
      type: GET_BORDER_CROSSING_BY_ID_FAIL,
      payload: error,
    });
    
    export const addNewBorderCrossing = banner => ({
      type: ADD_NEW_BORDER_CROSSING,
      payload: banner,
    });
    
    export const updateBorderCrossing = banner => ({
      type: UPDATE_BORDER_CROSSING,
      payload: banner,
    });
    
    export const deleteBorderCrossing = banner => ({
      type: DELETE_BORDER_CROSSING,
      payload: banner,
    });
    
    export const clearBorderCrossingError = () => ({
      type: BORDER_CROSSING_CLEAR_ERROR,
    });
    