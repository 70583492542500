import {
    GET_PESTICIDES, 
    GET_PESTICIDES_SUCCESS,
    GET_PESTICIDES_FAIL,
    GET_PESTICIDE_BY_ID,
    GET_PESTICIDE_BY_ID_SUCCESS,
    ADD_NEW_PESTICIDE,
    UPDATE_PESTICIDE,
    DELETE_PESTICIDE,
    PESTICIDE_CLEAR_ERROR,
  } from "./actionTypes";
  
  export const getPesticides = query => ({
    type: GET_PESTICIDES,
    payload: query,
  });
  
  export const getPesticidesSuccess = banners => ({
    type: GET_PESTICIDES_SUCCESS,
    payload: banners,
  });
    
  export const getPesticidesFail = error => ({
    type: GET_PESTICIDES_FAIL,
    payload: error,
  });
  
  export const getPesticideById = id => ({
    type: GET_PESTICIDE_BY_ID,
    payload: id,
  });
  
  export const getPesticideByIdSuccess = banner => ({
    type: GET_PESTICIDE_BY_ID_SUCCESS,
    payload: banner,
  });
  
  export const getPesticideByIdFail = error => ({
    type: GET_PESTICIDE_BY_ID_FAIL,
    payload: error,
  });
  
  export const addNewPesticide = banner => ({
    type: ADD_NEW_PESTICIDE,
    payload: banner,
  });
  
  export const updatePesticide = banner => ({
    type: UPDATE_PESTICIDE,
    payload: banner,
  });
  
  export const deletePesticide = banner => ({
    type: DELETE_PESTICIDE,
    payload: banner,
  });
  
  export const clearPesticideError = () => ({
    type: PESTICIDE_CLEAR_ERROR,
  });
  