import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  CardText,
} from "reactstrap";
import toastr from "toastr";
import classnames from "classnames";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import Form from "containers/pesticide-registration/Form";

export class PesticideRegistrationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "1",
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    console.log(id);

  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Settings"
              breadcrumbItem="Pesticide Registration Edit"
            />
            <Row>
              <Col lg={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggle("1");
                      }}
                    >
                      General Info
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggle("2");
                      }}
                    >
                      Attachments
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggle("3");
                      }}
                    >
                      Notes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggle("4");
                      }}
                    >
                      Tracking
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1"><Form/></TabPane>
                  <TabPane tabId="2">Tab 2</TabPane>
                  <TabPane tabId="3">Tab 3</TabPane>
                  <TabPane tabId="4">Tab 4</TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}


PesticideRegistrationDetail.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
 
};

export default PesticideRegistrationDetail;
