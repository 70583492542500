import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_CODE_TYPES,
  ADD_NEW_CODE_TYPE,
  GET_CODE_TYPE_BY_ID,
  UPDATE_CODE_TYPE,
  DELETE_CODE_TYPE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCodeTypesSuccess,
  getCodeTypeByIdSuccess,
  getCodeTypesFail,
} from "./actions";

const getCodeTypesRequest = query =>
  get("/api/CodeType/paging", { params: query });
const addNewCodeTypeRequest = item => post("/api/CodeType", item);
const getCodeTypeByIdRequest = id => get(`/api/CodeType/${id}`);
const updateCodeTypeRequest = item => update("/api/CodeType", item);
const deleteCodeTypeRequest = id => del(`/api/CodeType/${id}`);

function* fetchCodeTypes({ payload }) {
  try {
    const response = yield call(getCodeTypesRequest, payload);
    yield put(getCodeTypesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCodeTypesFail(error));
  }
}

function* addNewCodeType({ payload }) {
  try {
    yield call(addNewCodeTypeRequest, payload);
    const response = yield call(getCodeTypesRequest, {});
    yield put(getCodeTypesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCodeTypesFail(error));
  }
}

function* updateCodeType({ payload }) {
  try {
    yield call(updateCodeTypeRequest, payload);
    const response = yield call(getCodeTypesRequest, {});
    yield put(getCodeTypesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCodeTypesFail(error));
  }
}

function* getCodeTypeById({ payload }) {
  try {
    var response = yield call(getCodeTypeByIdRequest, payload);
    yield put(getCodeTypeByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCodeTypesFail(error));
  }
}

function* deleteCodeType({ payload }) {
  try {
    yield call(deleteCodeTypeRequest, payload);
    const response = yield call(getCodeTypesRequest, {});
    yield put(getCodeTypesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCodeTypesFail(error));
  }
}

function* CodeTypeSaga() {
  yield takeEvery(GET_CODE_TYPES, fetchCodeTypes);
  yield takeEvery(ADD_NEW_CODE_TYPE, addNewCodeType);
  yield takeEvery(GET_CODE_TYPE_BY_ID, getCodeTypeById);
  yield takeEvery(UPDATE_CODE_TYPE, updateCodeType);
  yield takeEvery(DELETE_CODE_TYPE, deleteCodeType);
}

export default CodeTypeSaga;
