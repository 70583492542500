// CODE_TYPE
export const GET_CODE_TYPES = "CODE_TYPES";
export const GET_CODE_TYPES_SUCCESS = "CODE_TYPES_SUCCESS";
export const GET_CODE_TYPES_FAIL = "CODE_TYPES_FAIL";

export const GET_CODE_TYPE_BY_ID = "GET_CODE_TYPE_BY_ID";
export const GET_CODE_TYPE_BY_ID_SUCCESS = "GET_CODE_TYPE_BY_ID_SUCCESS";

export const ADD_NEW_CODE_TYPE = "ADD_NEW_CODE_TYPE";

export const UPDATE_CODE_TYPE = "UPDATE_CODE_TYPE";

export const DELETE_CODE_TYPE = "DELETE_CODE_TYPE";

export const CODE_TYPE_CLEAR_ERROR = "CODE_TYPE_CLEAR_ERROR";
