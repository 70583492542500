import React, { Component } from "react";
import MetaTags from "react-meta-tags";
// Core viewer
// import { Viewer } from "@react-pdf-viewer/core";

// // Plugins
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";

// // Import styles
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// Create new plugin instance

import { Container } from "reactstrap";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import DemoPDF from "./DemoPDF";
import fileNme from "../../assets/02_June_2022_Active_job_list.pdf";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | JLS Logistics</title>
          </MetaTags>
          <Container fluid>
            {/* <PDFViewer showToolbar={true} width={500}>
              <DemoPDF />
            </PDFViewer> */}
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
