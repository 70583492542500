import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_UNITS,
  ADD_NEW_UNIT,
  GET_UNIT_BY_ID,
  UPDATE_UNIT,
  DELETE_UNIT,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import { getUnitsSuccess, getUnitByIdSuccess, getUnitsFail } from "./actions";

const getUnitsRequest = (query) => get("/api/Unit/paging", { params: query });
const addNewUnitRequest = (item) => post("/api/Unit", item);
const getUnitByIdRequest = (id) => get(`/api/Unit/${id}`);
const updateUnitRequest = (item) => update("/api/Unit", item);
const deleteUnitRequest = (id) => del(`/api/Unit/${id}`);

function* fetchUnits({ payload }) {
  try {
    const response = yield call(getUnitsRequest, payload);
    yield put(getUnitsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUnitsFail(error));
  }
}

function* addNewUnit({ payload }) {
  try {
    yield call(addNewUnitRequest, payload);
    const response = yield call(getUnitsRequest, {});
    yield put(getUnitsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUnitsFail(error));
  }
}

function* updateUnit({ payload }) {
  try {
    yield call(updateUnitRequest, payload);
    const response = yield call(getUnitsRequest, {});
    yield put(getUnitsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUnitsFail(error));
  }
}

function* getUnitById({ payload }) {
  try {
    var response = yield call(getUnitByIdRequest, payload);
    yield put(getUnitByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUnitsFail(error));
  }
}

function* deleteUnit({ payload }) {
  try {
    yield call(deleteUnitRequest, payload);
    const response = yield call(getUnitsRequest, {});
    yield put(getUnitsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getUnitsFail(error));
  }
}

function* unitSaga() {
  yield takeEvery(GET_UNITS, fetchUnits);
  yield takeEvery(ADD_NEW_UNIT, addNewUnit);
  yield takeEvery(GET_UNIT_BY_ID, getUnitById);
  yield takeEvery(UPDATE_UNIT, updateUnit);
  yield takeEvery(DELETE_UNIT, deleteUnit);
}

export default unitSaga;
