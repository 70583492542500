import {
  GET_UNITS,
  GET_UNITS_SUCCESS,
  GET_UNITS_FAIL,
  GET_UNIT_BY_ID,
  GET_UNIT_BY_ID_SUCCESS,
  ADD_NEW_UNIT,
  UPDATE_UNIT,
  DELETE_UNIT,
  UNIT_CLEAR_ERROR,
} from "./actionTypes";

export const getUnits = (query) => ({
  type: GET_UNITS,
  payload: query,
});

export const getUnitsSuccess = (banners) => ({
  type: GET_UNITS_SUCCESS,
  payload: banners,
});

export const getUnitsFail = (error) => ({
  type: GET_UNITS_FAIL,
  payload: error,
});

export const getUnitById = (id) => ({
  type: GET_UNIT_BY_ID,
  payload: id,
});

export const getUnitByIdSuccess = (banner) => ({
  type: GET_UNIT_BY_ID_SUCCESS,
  payload: banner,
});

export const getUnitByIdFail = (error) => ({
  type: GET_UNIT_BY_ID_FAIL,
  payload: error,
});

export const addNewUnit = (banner) => ({
  type: ADD_NEW_UNIT,
  payload: banner,
});

export const updateUnit = (banner) => ({
  type: UPDATE_UNIT,
  payload: banner,
});

export const deleteUnit = (banner) => ({
  type: DELETE_UNIT,
  payload: banner,
});

export const clearUnitError = () => ({
  type: UNIT_CLEAR_ERROR,
});
