import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      name: "",
      systemName: "",
      submitted: false,
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, name, systemName } = this.state;
    this.setState({
      submitted: true,
    });

    const item = {
      id,
      name,
      systemName,
    };

    var isValid = item.name;

    if (isValid) {
      this.props.onSubmit(item);
    }
  };

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      console.log(item);
      this.setState({
        ...item,
       // description: item.description ?? "",
      });
    } else {
      this.setState({
        id: 0,
        name: "",
        systemName: "",
        submitted: false,
      });
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal size="md" onOpened={this.onOpened} isOpen={isOpen} toggle={toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={submitted && !this.state.name ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Name is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">System Name</Label>
                <Input
                  type="text"
                  name="systemName"
                  placeholder="System Name"
                  className={submitted && !this.state.systemName ? "is-invalid" : ""}
                  value={this.state.systemName}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.systemName && (
                  <div className="invalid-feedback-custom">
                    System Name is required
                  </div>
                )}
              </FormGroup>
              
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default ModalForm;
