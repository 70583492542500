import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_BORDER_CROSSINGS,
  ADD_NEW_BORDER_CROSSING,
  GET_BORDER_CROSSING_BY_ID,
  UPDATE_BORDER_CROSSING,
  DELETE_BORDER_CROSSING,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getBorderCrossingsSuccess,
  getBorderCrossingByIdSuccess,
  getBorderCrossingsFail,
} from "./actions";

const getBorderCrossingsRequest = query =>
  get("/api/BorderCrossing/paging", { params: query });
const addNewBorderCrossingRequest = item => post("/api/BorderCrossing", item);
const getBorderCrossingByIdRequest = id => get(`/api/BorderCrossing/${id}`);
const updateBorderCrossingRequest = item => update("/api/BorderCrossing", item);
const deleteBorderCrossingRequest = id => del(`/api/BorderCrossing/${id}`);

function* fetchBorderCrossings({ payload }) {
  try {
    const response = yield call(getBorderCrossingsRequest, payload);
    yield put(getBorderCrossingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBorderCrossingsFail(error));
  }
}

function* addNewBorderCrossing({ payload }) {
  try {
    yield call(addNewBorderCrossingRequest, payload);
    const response = yield call(getBorderCrossingsRequest, {});
    yield put(getBorderCrossingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBorderCrossingsFail(error));
  }
}

function* updateBorderCrossing({ payload }) {
  try {
    yield call(updateBorderCrossingRequest, payload);
    const response = yield call(getBorderCrossingsRequest, {});
    yield put(getBorderCrossingsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBorderCrossingsFail(error));
  }
}

function* getBorderCrossingById({ payload }) {
  try {
    var response = yield call(getBorderCrossingByIdRequest, payload);
    yield put(getBorderCrossingByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBorderCrossingsFail(error));
  }
}

function* deleteBorderCrossing({ payload }) {
  try {
    yield call(deleteBorderCrossingRequest, payload);
    const response = yield call(getBorderCrossingsRequest, {});
    yield put(getBorderCrossingsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBorderCrossingsFail(error));
  }
}

function* BorderCrossingSaga() {
  yield takeEvery(GET_BORDER_CROSSINGS, fetchBorderCrossings);
  yield takeEvery(ADD_NEW_BORDER_CROSSING, addNewBorderCrossing);
  yield takeEvery(GET_BORDER_CROSSING_BY_ID, getBorderCrossingById);
  yield takeEvery(UPDATE_BORDER_CROSSING, updateBorderCrossing);
  yield takeEvery(DELETE_BORDER_CROSSING, deleteBorderCrossing);
}

export default BorderCrossingSaga;
