import React, { Component } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";

import pdfFile from "../../assets/02_June_2022_Active_job_list.pdf";
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export class DemoPDF extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return <Document url={pdfFile}></Document>;
  }
}

export default DemoPDF;
