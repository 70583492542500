import {
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_FAIL,
    GET_COMPANY_BY_ID,
    GET_COMPANY_BY_ID_SUCCESS,
    ADD_NEW_COMPANY,
    UPDATE_COMPANY,
    DELETE_COMPANY,
    COMPANY_CLEAR_ERROR,
  } from "./actionTypes";
  
  export const getCompanies = query => ({
    type: GET_COMPANIES,
    payload: query,
  });
  
  export const getCompaniesSuccess = banners => ({
    type: GET_COMPANIES_SUCCESS,
    payload: banners,
  });
    
  export const getCompaniesFail = error => ({
    type: GET_COMPANIES_FAIL,
    payload: error,
  });
  
  export const getCompanyById = id => ({
    type: GET_COMPANY_BY_ID,
    payload: id,
  });
  
  export const getCompanyByIdSuccess = banner => ({
    type: GET_COMPANY_BY_ID_SUCCESS,
    payload: banner,
  });
  
  export const getCountryByIdFail = error => ({
    type: GET_COUNTRY_BY_ID_FAIL,
    payload: error,
  });
  
  export const addNewCompany = banner => ({
    type: ADD_NEW_COMPANY,
    payload: banner,
  });
  
  export const updateCompany = banner => ({
    type: UPDATE_COMPANY,
    payload: banner,
  });
  
  export const deleteCompany = banner => ({
    type: DELETE_COMPANY,
    payload: banner,
  });
  
  export const clearCompanyError = () => ({
    type: COMPANY_CLEAR_ERROR,
  });
  