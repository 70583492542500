import {
  GET_PESTICIDE_REGISTRATIONS,
  GET_PESTICIDE_REGISTRATIONS_SUCCESS,
  GET_PESTICIDE_REGISTRATIONS_FAIL,
  GET_PESTICIDE_REGISTRATION_BY_ID,
  GET_PESTICIDE_REGISTRATION_BY_ID_SUCCESS,
  ADD_NEW_PESTICIDE_REGISTRATION,
  UPDATE_PESTICIDE_REGISTRATION,
  DELETE_PESTICIDE_REGISTRATION,
  PESTICIDE_REGISTRATION_CLEAR_ERROR,
} from "./actionTypes";

export const getPesticideRegistrations = (query) => ({
  type: GET_PESTICIDE_REGISTRATIONS,
  payload: query,
});

export const getPesticideRegistrationsSuccess = (banners) => ({
  type: GET_PESTICIDE_REGISTRATIONS_SUCCESS,
  payload: banners,
});

export const getPesticideRegistrationsFail = (error) => ({
  type: GET_PESTICIDE_REGISTRATIONS_FAIL,
  payload: error,
});

export const getPesticideRegistrationById = (id) => ({
  type: GET_PESTICIDE_REGISTRATION_BY_ID,
  payload: id,
});

export const getPesticideRegistrationByIdSuccess = (banner) => ({
  type: GET_PESTICIDE_REGISTRATION_BY_ID_SUCCESS,
  payload: banner,
});

export const getPesticideRegistrationByIdFail = (error) => ({
  type: GET_PESTICIDE_REGISTRATION_BY_ID_FAIL,
  payload: error,
});

export const addNewPesticideRegistration = (banner) => ({
  type: ADD_NEW_PESTICIDE_REGISTRATION,
  payload: banner,
});

export const updatePesticideRegistration = (banner) => ({
  type: UPDATE_PESTICIDE_REGISTRATION,
  payload: banner,
});

export const deletePesticideRegistration = (banner) => ({
  type: DELETE_PESTICIDE_REGISTRATION,
  payload: banner,
});

export const clearPesticideRegistrationError = () => ({
  type: PESTICIDE_REGISTRATION_CLEAR_ERROR,
});
