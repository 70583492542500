import {
  GET_LANGUAGES,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAIL,
  GET_LANGUAGE_BY_ID,
  GET_LANGUAGE_BY_ID_SUCCESS,
  ADD_NEW_LANGUAGE,
  UPDATE_LANGUAGE,
  DELETE_LANGUAGE,
  LANGUAGE_CLEAR_ERROR,
} from "./actionTypes";

export const getLanguages = (query) => ({
  type: GET_LANGUAGES,
  payload: query,
});

export const getLanguagesSuccess = (banners) => ({
  type: GET_LANGUAGES_SUCCESS,
  payload: banners,
});

export const getLanguagesFail = (error) => ({
  type: GET_LANGUAGES_FAIL,
  payload: error,
});

export const getLanguageById = (id) => ({
  type: GET_LANGUAGE_BY_ID,
  payload: id,
});

export const getLanguageByIdSuccess = (banner) => ({
  type: GET_LANGUAGE_BY_ID_SUCCESS,
  payload: banner,
});

export const getLanguageByIdFail = (error) => ({
  type: GET_LANGUAGE_BY_ID_FAIL,
  payload: error,
});

export const addNewLanguage = (banner) => ({
  type: ADD_NEW_LANGUAGE,
  payload: banner,
});

export const updateLanguage = (banner) => ({
  type: UPDATE_LANGUAGE,
  payload: banner,
});

export const deleteLanguage = (banner) => ({
  type: DELETE_LANGUAGE,
  payload: banner,
});

export const clearLanguageError = () => ({
  type: LANGUAGE_CLEAR_ERROR,
});
