import React from "react";
import { Redirect } from "react-router-dom";
// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Settings from "../pages/Settings/index";
import CountryList from "../pages/Country/country-list";
import CodeTypeList from "pages/CodeType/code-type-list";
import BorderCrossingList from "pages/BorderCrossing/border-crossing-list";
import CompanyList from "pages/Company/company-list";
import PesticideList from "pages/Pesticide/pesticide-list";
import FertilizerList from "pages/Fertilizer/fertilizer-list";
import UnitList from "pages/Unit/unit-list";
import BannedPesticideList from "pages/BannedPesticide/banned-pesticide-list";
import RestrictedPesticideList from "pages/RestrictedPesticide/restricted-pesticide-list";
import LanguageList from "pages/Languag/language-list";
import RoleList from "pages/Role/role-list";
import PermissionList from "pages/Permission/permission-list";
import PesticideRegistrationList from "pages/PesticideRegistration/pesticide-registration-list";

import AddNewPesticideRegistration from "pages/PesticideRegistration/add-new-pesticide-registration";
import PesticideRegistrationDetail from "pages/PesticideRegistration/pesticide-registration-detail";
// import PesticideRegistrationList from "pages/PesticideRegistration/pesticide-registration-list";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/pesticide-registration", component: PesticideRegistrationList },
  {
    path: "/pesticide-registration/add-new",
    component: AddNewPesticideRegistration,
  },
  {
    path: "/pesticide-registration/:id",
    component: PesticideRegistrationDetail,
  },

  //profile
  { path: "/profile", component: UserProfile },
  { path: "/setting", component: Settings },
  { path: "/country", component: CountryList },
  { path: "/code-type", component: CodeTypeList },
  { path: "/border-crossing", component: BorderCrossingList },
  { path: "/company", component: CompanyList },
  { path: "/pesticide", component: PesticideList },
  { path: "/fertilizer", component: FertilizerList },
  { path: "/unit", component: UnitList },
  { path: "/banned-pesticide", component: BannedPesticideList },
  { path: "/restricted-pesticide", component: RestrictedPesticideList },
  { path: "/languag", component: LanguageList },
  { path: "/role", component: RoleList },
  { path: "/permission", component: PermissionList },
  { path: "/pesticide-registration", component: PesticideRegistrationList },

  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
