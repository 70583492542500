import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_RESTRICTED_PESTICIDES,
  ADD_NEW_RESTRICTED_PESTICIDE,
  GET_RESTRICTED_PESTICIDE_BY_ID,
  UPDATE_RESTRICTED_PESTICIDE,
  DELETE_RESTRICTED_PESTICIDE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getRestrictedPesticidesSuccess,
  getRestrictedPesticideByIdSuccess,
  getRestrictedPesticidesFail,
} from "./actions";

const getRestrictedPesticidesRequest = (query) =>
  get("/api/RestrictedPesticide/paging", { params: query });
const addNewRestrictedPesticideRequest = (item) =>
  post("/api/RestrictedPesticide", item);
const getRestrictedPesticideByIdRequest = (id) =>
  get(`/api/RestrictedPesticide/${id}`);
const updateRestrictedPesticideRequest = (item) =>
  update("/api/RestrictedPesticide", item);
const deleteRestrictedPesticideRequest = (id) =>
  del(`/api/RestrictedPesticide/${id}`);

function* fetchRestrictedPesticides({ payload }) {
  try {
    const response = yield call(getRestrictedPesticidesRequest, payload);
    yield put(getRestrictedPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRestrictedPesticidesFail(error));
  }
}

function* addNewRestrictedPesticide({ payload }) {
  try {
    yield call(addNewRestrictedPesticideRequest, payload);
    const response = yield call(getRestrictedPesticidesRequest, {});
    yield put(getRestrictedPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRestrictedPesticidesFail(error));
  }
}

function* updateRestrictedPesticide({ payload }) {
  try {
    yield call(updateRestrictedPesticideRequest, payload);
    const response = yield call(getRestrictedPesticidesRequest, {});
    yield put(getRestrictedPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRestrictedPesticidesFail(error));
  }
}

function* getRestrictedPesticideById({ payload }) {
  try {
    var response = yield call(getRestrictedPesticideByIdRequest, payload);
    yield put(getRestrictedPesticideByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRestrictedPesticidesFail(error));
  }
}

function* deleteRestrictedPesticide({ payload }) {
  try {
    yield call(deleteRestrictedPesticideRequest, payload);
    const response = yield call(getRestrictedPesticidesRequest, {});
    yield put(getRestrictedPesticidesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRestrictedPesticidesFail(error));
  }
}

function* restrictedPesticideSaga() {
  yield takeEvery(GET_RESTRICTED_PESTICIDES, fetchRestrictedPesticides);
  yield takeEvery(ADD_NEW_RESTRICTED_PESTICIDE, addNewRestrictedPesticide);
  yield takeEvery(GET_RESTRICTED_PESTICIDE_BY_ID, getRestrictedPesticideById);
  yield takeEvery(UPDATE_RESTRICTED_PESTICIDE, updateRestrictedPesticide);
  yield takeEvery(DELETE_RESTRICTED_PESTICIDE, deleteRestrictedPesticide);
}

export default restrictedPesticideSaga;
