import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PESTICIDE_REGISTRATIONS,
  ADD_NEW_PESTICIDE_REGISTRATION,
  GET_PESTICIDE_REGISTRATION_BY_ID,
  UPDATE_PESTICIDE_REGISTRATION,
  DELETE_PESTICIDE_REGISTRATION,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPesticideRegistrationsSuccess,
  getPesticideRegistrationByIdSuccess,
  getPesticideRegistrationsFail,
} from "./actions";

const getPesticideRegistrationsRequest = (query) =>
  get("/api/PesticideRegistration/paging", { params: query });
const addNewPesticideRegistrationRequest = (item) =>
  post("/api/PesticideRegistration", item);
const getPesticideRegistrationByIdRequest = (id) =>
  get(`/api/PesticideRegistration/${id}`);
const updatePesticideRegistrationRequest = (item) =>
  update("/api/PesticideRegistration", item);
const deletePesticideRegistrationRequest = (id) =>
  del(`/api/PesticideRegistration/${id}`);

function* fetchPesticideRegistrations({ payload }) {
  try {
    const response = yield call(getPesticideRegistrationsRequest, payload);
    yield put(getPesticideRegistrationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticideRegistrationsFail(error));
  }
}

function* addNewPesticideRegistration({ payload }) {
  try {
    yield call(addNewPesticideRegistrationRequest, payload);
    const response = yield call(getPesticideRegistrationsRequest, {});
    yield put(getPesticideRegistrationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticideRegistrationsFail(error));
  }
}

function* updatePesticideRegistration({ payload }) {
  try {
    yield call(updatePesticideRegistrationRequest, payload);
    const response = yield call(getPesticideRegistrationsRequest, {});
    yield put(getPesticideRegistrationsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticideRegistrationsFail(error));
  }
}

function* getPesticideRegistrationById({ payload }) {
  try {
    var response = yield call(getPesticideRegistrationByIdRequest, payload);
    yield put(getPesticideRegistrationByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticideRegistrationsFail(error));
  }
}

function* deletePesticideRegistration({ payload }) {
  try {
    yield call(deletePesticideRegistrationRequest, payload);
    const response = yield call(getPesticideRegistrationsRequest, {});
    yield put(getPesticideRegistrationsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticideRegistrationsFail(error));
  }
}

function* pesticideRegistrationSaga() {
  yield takeEvery(GET_PESTICIDE_REGISTRATIONS, fetchPesticideRegistrations);
  yield takeEvery(ADD_NEW_PESTICIDE_REGISTRATION, addNewPesticideRegistration);
  yield takeEvery(
    GET_PESTICIDE_REGISTRATION_BY_ID,
    getPesticideRegistrationById
  );
  yield takeEvery(UPDATE_PESTICIDE_REGISTRATION, updatePesticideRegistration);
  yield takeEvery(DELETE_PESTICIDE_REGISTRATION, deletePesticideRegistration);
}

export default pesticideRegistrationSaga;
