import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { del, get, post, put } from "../../helpers/api_helper";

export class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      firstName: null,
      lastName: null,
      env: "",
      envData: "",
    };
  }

  componentDidMount() {
    var env = process.env.NODE_ENV;
    this.setState({
      env,
    });

    // console.log(env);
    // get("/api/Home").then(res => {
    //   const { id, firstName, lastName } = { ...res };
    //   this.setState({
    //     id,
    //     firstName,
    //     lastName,
    //   });
    // });
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | JLS Logistics</title>
          </MetaTags>
          <Container fluid>
            <h1>Environemnt: {this.state.env}</h1>
            <h1>API URL: {process.env.REACT_APP_API_URL}</h1>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Settings;
