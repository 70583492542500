// COUNTRY
export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_COUNTRY_BY_ID = "GET_COUNTRY_BY_ID";
export const GET_COUNTRY_BY_ID_SUCCESS = "GET_COUNTRY_BY_ID_SUCCESS";

export const ADD_NEW_COUNTRY = "ADD_NEW_COUNTRY";

export const UPDATE_COUNTRY = "UPDATE_COUNTRY";

export const DELETE_COUNTRY = "DELETE_COUNTRY";

export const COUNTRY_CLEAR_ERROR = "COUNTRY_CLEAR_ERROR";
