// PESTICIDE
import {
    GET_PESTICIDES,
    GET_PESTICIDES_SUCCESS,
    GET_PESTICIDES_FAIL,
    GET_PESTICIDE_BY_ID_SUCCESS,
  } from "./actionTypes.js";
  const initState = {
    items: [],
    item: null,
    error: null,
    itemCount: 0,
    pageCount: 0,
    loading: false,
  };
  
  const Pesticide = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_PESTICIDES:
        return {
          ...state,
          loading: true,
        };
      case GET_PESTICIDES_SUCCESS:
        const { itemCount, pageCount, data } = payload;
        return {
          ...state,
          itemCount,
          pageCount,
          items: data,
          loading: false,
        };
      case GET_PESTICIDE_BY_ID_SUCCESS:
        return {
          ...state,
          item: payload,
        };
      case GET_PESTICIDES_FAIL:
        return {
          ...state,
          error: payload,
        };
      default:
        return state;
    }
  };
  
  export default Pesticide;
  