import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_FERTILIZERS,
  ADD_NEW_FERTILIZER,
  GET_FERTILIZER_BY_ID,
  UPDATE_FERTILIZER,
  DELETE_FERTILIZER,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getFertilizersSuccess,
  getFertilizerByIdSuccess,
  getFertilizersFail,
} from "./actions";

const getFertilizersRequest = (query) =>
  get("/api/Fertilizer/paging", { params: query });
const addNewFertilizerRequest = (item) => post("/api/Fertilizer", item);
const getFertilizerByIdRequest = (id) => get(`/api/Fertilizer/${id}`);
const updateFertilizerRequest = (item) => update("/api/Fertilizer", item);
const deleteFertilizerRequest = (id) => del(`/api/Fertilizer/${id}`);

function* fetchFertilizers({ payload }) {
  try {
    const response = yield call(getFertilizersRequest, payload);
    yield put(getFertilizersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getFertilizersFail(error));
  }
}

function* addNewFertilizer({ payload }) {
  try {
    yield call(addNewFertilizerRequest, payload);
    const response = yield call(getFertilizersRequest, {});
    yield put(getFertilizersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getFertilizersFail(error));
  }
}

function* updateFertilizer({ payload }) {
  try {
    yield call(updateFertilizerRequest, payload);
    const response = yield call(getFertilizersRequest, {});
    yield put(getFertilizersSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getFertilizersFail(error));
  }
}

function* getFertilizerById({ payload }) {
  try {
    var response = yield call(getFertilizerByIdRequest, payload);
    yield put(getFertilizerByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getFertilizersFail(error));
  }
}

function* deleteFertilizer({ payload }) {
  try {
    yield call(deleteFertilizerRequest, payload);
    const response = yield call(getFertilizersRequest, {});
    yield put(getFertilizersSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getFertilizersFail(error));
  }
}

function* fertilizerSaga() {
  yield takeEvery(GET_FERTILIZERS, fetchFertilizers);
  yield takeEvery(ADD_NEW_FERTILIZER, addNewFertilizer);
  yield takeEvery(GET_FERTILIZER_BY_ID, getFertilizerById);
  yield takeEvery(UPDATE_FERTILIZER, updateFertilizer);
  yield takeEvery(DELETE_FERTILIZER, deleteFertilizer);
}

export default fertilizerSaga;
