import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import country from "./country/reducer";
import codeType from "./code-type/reducer";
import borderCrossing from "./border-crossing/reducer";
import company from "./company/reducer";
import pesticide from "./pesticide/reducer";
import fertilizer from "./fertilizer/reducer";
import unit from "./unit/reducer";
import bannedPesticide from "./banned-pesticide/reducer";
import restrictedPesticide from "./restricted-pesticide/reducer";
import language from "./languag/reducer";
import role from "./role/reducer";
import permission from "./permission/reducer";
import pesticideRegistration from "./pesticide-registration/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  country,
  codeType,
  borderCrossing,
  company,
  pesticide,
  fertilizer,
  unit,
  bannedPesticide,
  restrictedPesticide,
  language,
  role,
  permission,
  pesticideRegistration,
});

export default rootReducer;
