import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PESTICIDES,
  ADD_NEW_PESTICIDE,
  GET_PESTICIDE_BY_ID,
  UPDATE_PESTICIDE,
  DELETE_PESTICIDE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPesticidesSuccess,
  getPesticideByIdSuccess,
  getPesticidesFail,
} from "./actions";

const getPesticidesRequest = query =>
  get("/api/Pesticide/paging", { params: query });
const addNewPesticideRequest = item => post("/api/Pesticide", item);
const getPesticideByIdRequest = id => get(`/api/Pesticide/${id}`);
const updatePesticideRequest = item => update("/api/Pesticide", item);
const deletePesticideRequest = id => del(`/api/Pesticide/${id}`);

function* fetchPesticides({ payload }) {
  try {
    const response = yield call(getPesticidesRequest, payload);
    yield put(getPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticidesFail(error));
  }
}

function* addNewPesticide({ payload }) {
  try {
    yield call(addNewPesticideRequest, payload);
    const response = yield call(getPesticidesRequest, {});
    yield put(getPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticidesFail(error));
  }
}

function* updatePesticide({ payload }) {
  try {
    yield call(updatePesticideRequest, payload);
    const response = yield call(getPesticidesRequest, {});
    yield put(getPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticidesFail(error));
  }
}

function* getPesticideById({ payload }) {
  try {
    var response = yield call(getPesticideByIdRequest, payload);
    yield put(getPesticideByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticidesFail(error));
  }
}

function* deletePesticide({ payload }) {
  try {
    yield call(deletePesticideRequest, payload);
    const response = yield call(getPesticidesRequest, {});
    yield put(getPesticidesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPesticidesFail(error));
  }
}

function* pesticideSaga() {
  yield takeEvery(GET_PESTICIDES, fetchPesticides);
  yield takeEvery(ADD_NEW_PESTICIDE, addNewPesticide);
  yield takeEvery(GET_PESTICIDE_BY_ID, getPesticideById);
  yield takeEvery(UPDATE_PESTICIDE, updatePesticide);
  yield takeEvery(DELETE_PESTICIDE, deletePesticide);
}

export default pesticideSaga;
