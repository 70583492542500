import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAIL,
  GET_ROLE_BY_ID,
  GET_ROLE_BY_ID_SUCCESS,
  ADD_NEW_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  ROLE_CLEAR_ERROR,
} from "./actionTypes";

export const getRoles = (query) => ({
  type: GET_ROLES,
  payload: query,
});

export const getRolesSuccess = (banners) => ({
  type: GET_ROLES_SUCCESS,
  payload: banners,
});

export const getRolesFail = (error) => ({
  type: GET_ROLES_FAIL,
  payload: error,
});

export const getRoleById = (id) => ({
  type: GET_ROLE_BY_ID,
  payload: id,
});

export const getRoleByIdSuccess = (banner) => ({
  type: GET_ROLE_BY_ID_SUCCESS,
  payload: banner,
});

export const getRoleByIdFail = (error) => ({
  type: GET_ROLE_BY_ID_FAIL,
  payload: error,
});

export const addNewRole = (banner) => ({
  type: ADD_NEW_ROLE,
  payload: banner,
});

export const updateRole = (banner) => ({
  type: UPDATE_ROLE,
  payload: banner,
});

export const deleteRole = (banner) => ({
  type: DELETE_ROLE,
  payload: banner,
});

export const clearRoleError = () => ({
  type: ROLE_CLEAR_ERROR,
});
