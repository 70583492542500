import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";

import countrySaga from "./country/saga";
import codeTypeSaga from "./code-type/saga";
import borderCrossingSaga from "./border-crossing/saga";
import companySaga from "./company/saga";
import pesticideSaga from "./pesticide/saga";
import fertilizerSaga from "./fertilizer/saga";
import unitSaga from "./unit/saga";
import bannedPesticideSaga from "./banned-pesticide/saga";
import restrictedPesticideSaga from "./restricted-pesticide/saga";
import languageSaga from "./languag/saga";
import roleSaga from "./role/saga";
import permissionSaga from "./permission/saga";
import pesticideRegistrationSaga from "./pesticide-registration/saga";

export default function* rootSaga() {
  yield all([
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(countrySaga),
    fork(codeTypeSaga),
    fork(borderCrossingSaga),
    fork(companySaga),
    fork(pesticideSaga),
    fork(fertilizerSaga),
    fork(unitSaga),
    fork(bannedPesticideSaga),
    fork(restrictedPesticideSaga),
    fork(languageSaga),
    fork(roleSaga),
    fork(permissionSaga),
    fork(pesticideRegistrationSaga),
  ]);
}
