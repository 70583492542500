import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_ROLES,
  ADD_NEW_ROLE,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
  DELETE_ROLE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import { getRolesSuccess, getRoleByIdSuccess, getRolesFail } from "./actions";

const getRolesRequest = (query) => get("/api/Role/paging", { params: query });
const addNewRoleRequest = (item) => post("/api/Role", item);
const getRoleByIdRequest = (id) => get(`/api/Role/${id}`);
const updateRoleRequest = (item) => update("/api/Role", item);
const deleteRoleRequest = (id) => del(`/api/Role/${id}`);

function* fetchRoles({ payload }) {
  try {
    const response = yield call(getRolesRequest, payload);
    yield put(getRolesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* addNewRole({ payload }) {
  try {
    yield call(addNewRoleRequest, payload);
    const response = yield call(getRolesRequest, {});
    yield put(getRolesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* updateRole({ payload }) {
  try {
    yield call(updateRoleRequest, payload);
    const response = yield call(getRolesRequest, {});
    yield put(getRolesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* getRoleById({ payload }) {
  try {
    var response = yield call(getRoleByIdRequest, payload);
    yield put(getRoleByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* deleteRole({ payload }) {
  try {
    yield call(deleteRoleRequest, payload);
    const response = yield call(getRolesRequest, {});
    yield put(getRolesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getRolesFail(error));
  }
}

function* roleSaga() {
  yield takeEvery(GET_ROLES, fetchRoles);
  yield takeEvery(ADD_NEW_ROLE, addNewRole);
  yield takeEvery(GET_ROLE_BY_ID, getRoleById);
  yield takeEvery(UPDATE_ROLE, updateRole);
  yield takeEvery(DELETE_ROLE, deleteRole);
}

export default roleSaga;
