import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, FormGroup, Input, Label } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";

export class AddNewPesticideRegistration extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Settings"
              breadcrumbItem="Pesticide Registration Add New"
            />
            <Row>
            <Col lg="12">
                dfsfdsfds
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

export default AddNewPesticideRegistration;
