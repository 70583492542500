// COUNTRY
import {
    GET_CODE_TYPES,
    GET_CODE_TYPES_SUCCESS,
    GET_CODE_TYPES_FAIL,
    GET_CODE_TYPE_BY_ID_SUCCESS,
  } from "./actionTypes.js";
  const initState = {
    items: [],
    item: null,
    error: null,
    itemCount: 0,
    pageCount: 0,
    loading: false,
  };
  
  const CodeType = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_CODE_TYPES:
        return {
          ...state,
          loading: true,
        };
      case GET_CODE_TYPES_SUCCESS:
        const { itemCount, pageCount, data } = payload;
        return {
          ...state,
          itemCount,
          pageCount,
          items: data,
          loading: false,
        };
      case GET_CODE_TYPE_BY_ID_SUCCESS:
        return {
          ...state,
          item: payload,
        };
      case GET_CODE_TYPES_FAIL:
        return {
          ...state,
          error: payload,
        };
      default:
        return state;
    }
  };
  
  export default CodeType;
  