import {
  GET_RESTRICTED_PESTICIDES,
  GET_RESTRICTED_PESTICIDES_SUCCESS,
  GET_RESTRICTED_PESTICIDES_FAIL,
  GET_RESTRICTED_PESTICIDE_BY_ID,
  GET_RESTRICTED_PESTICIDE_BY_ID_SUCCESS,
  ADD_NEW_RESTRICTED_PESTICIDE,
  UPDATE_RESTRICTED_PESTICIDE,
  DELETE_RESTRICTED_PESTICIDE,
  RESTRICTED_PESTICIDE_CLEAR_ERROR,
} from "./actionTypes";

export const getRestrictedPesticides = (query) => ({
  type: GET_RESTRICTED_PESTICIDES,
  payload: query,
});

export const getRestrictedPesticidesSuccess = (banners) => ({
  type: GET_RESTRICTED_PESTICIDES_SUCCESS,
  payload: banners,
});

export const getRestrictedPesticidesFail = (error) => ({
  type: GET_RESTRICTED_PESTICIDES_FAIL,
  payload: error,
});

export const getRestrictedPesticideById = (id) => ({
  type: GET_RESTRICTED_PESTICIDE_BY_ID,
  payload: id,
});

export const getRestrictedPesticideByIdSuccess = (banner) => ({
  type: GET_RESTRICTED_PESTICIDE_BY_ID_SUCCESS,
  payload: banner,
});

export const getRestrictedPesticideByIdFail = (error) => ({
  type: GET_RESTRICTED_PESTICIDE_BY_ID_FAIL,
  payload: error,
});

export const addNewRestrictedPesticide = (banner) => ({
  type: ADD_NEW_RESTRICTED_PESTICIDE,
  payload: banner,
});

export const updateRestrictedPesticide = (banner) => ({
  type: UPDATE_RESTRICTED_PESTICIDE,
  payload: banner,
});

export const deleteRestrictedPesticide = (banner) => ({
  type: DELETE_RESTRICTED_PESTICIDE,
  payload: banner,
});

export const clearRestrictedPesticideError = () => ({
  type: RESTRICTED_PESTICIDE_CLEAR_ERROR,
});
