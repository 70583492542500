// COMPANY
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL";

export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";

export const ADD_NEW_COMPANY = "ADD_NEW_COMPANY";

export const UPDATE_COMPANY = "UPDATE_COMPANY";

export const DELETE_COMPANY = "DELETE_COMPANY";

export const COMPANY_CLEAR_ERROR = "COMPANY_CLEAR_ERROR";
 
