import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_PERMISSION_BY_ID,
  GET_PERMISSION_BY_ID_SUCCESS,
  ADD_NEW_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  PERMISSION_CLEAR_ERROR,
} from "./actionTypes";

export const getPermissions = (query) => ({
  type: GET_PERMISSIONS,
  payload: query,
});

export const getPermissionsSuccess = (banners) => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload: banners,
});

export const getPermissionsFail = (error) => ({
  type: GET_PERMISSIONS_FAIL,
  payload: error,
});

export const getPermissionById = (id) => ({
  type: GET_PERMISSION_BY_ID,
  payload: id,
});

export const getPermissionByIdSuccess = (banner) => ({
  type: GET_PERMISSION_BY_ID_SUCCESS,
  payload: banner,
});

export const getPermissionByIdFail = (error) => ({
  type: GET_PERMISSION_BY_ID_FAIL,
  payload: error,
});

export const addNewPermission = (banner) => ({
  type: ADD_NEW_PERMISSION,
  payload: banner,
});

export const updatePermission = (banner) => ({
  type: UPDATE_PERMISSION,
  payload: banner,
});

export const deletePermission = (banner) => ({
  type: DELETE_PERMISSION,
  payload: banner,
});

export const clearPermissionError = () => ({
  type: PERMISSION_CLEAR_ERROR,
});
