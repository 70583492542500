// LANGUAGE
export const GET_LANGUAGES = "GET_LANGUAGES";
export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const GET_LANGUAGE_BY_ID = "GET_LANGUAGE_BY_ID";
export const GET_LANGUAGE_BY_ID_SUCCESS = "GET_LANGUAGE_BY_ID_SUCCESS";

export const ADD_NEW_LANGUAGE = "ADD_NEW_LANGUAGE";

export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";

export const DELETE_LANGUAGE = "DELETE_LANGUAGE";

export const LANGUAGE_CLEAR_ERROR = "LANGUAGE_CLEAR_ERROR";
