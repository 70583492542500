import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { codeTypeService } from "services/code-type-service";

const typeOptions=[
  {key: 0, value: 'Organic', label: 'Organic'},
  {key: 1, value: 'Inorganic', label: 'Inorganic'},
  {key: 2, value: 'Bio', label: 'Bio'},
]

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      name: "",
      description: "",
      type: "",
      primaryNutrients: "",
      secondaryAndMicroNutrients: "",
      physicalPropertyId: 0,
      chemicalPropertyId: 0,
      useForOnId: 0,
      useForOn: "",
      submitted: false,
      physicalPropertyOptions:[],
      useForOnOptions:[],
    };
  }

  componentDidMount(){
    codeTypeService.getByTypeIdRequest(12).then(data =>{
      if(data){
         this.setState({
          physicalPropertyOptions: data.map(a =>{
            return {
              key: a.id,
              value: a.id,
              label: a.name
            }
          })
         })
      }
    })

    codeTypeService.getByTypeIdRequest(6).then(data =>{
      if(data){
         this.setState({
          useForOnOptions: data.map(a =>{
            return {
              key: a.id,
              value: a.id,
              label: a.name
            }
          })
         })
      }
    })
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, name, type,  primaryNutrients,  secondaryAndMicroNutrients,  physicalPropertyId,  chemicalPropertyId,  useForOnId,  useForOn, description } = this.state;
    this.setState({
      submitted: true,
    });
   

    const item = {
      id,
      name,
      description,
      type: type?.value ?? null,
      primaryNutrients,
      secondaryAndMicroNutrients,
      physicalPropertyId: physicalPropertyId?.value ?? null,
      useForOnId: useForOnId?.value ?? null,
    };


  var isValid = item.name && item.physicalPropertyId;
console.log("ssrs");
console.log(item);
  if (isValid) {
    this.props.onSubmit(item);
  }
};

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      console.log(item);
      this.setState({
        ...item,
        physicalPropertyId: {key: item.physicalPropertyId, value: item.physicalPropertyId, label: item.physicalProperty },
        type: {key: item.type, value: item.type, label: item.type },
        useForOnId: {key: item.useForOnId, value: item.useForOnId, label: item.useForOn },
        description: item.description ?? "",
      });
    } else {
      this.setState({
        id: 0,
        name: "",
        description: "",
        type: "",
        primaryNutrients: "",
        secondaryAndMicroNutrients: "",
        physicalPropertyId: 0,
        chemicalPropertyId: 0,
        useForOnId: 0,
        useForOn: "",
        submitted: false,
      });
    }
  };
  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal size="md" onOpened={this.onOpened} isOpen={isOpen} toggle={toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">NPK Content</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={submitted && !this.state.name ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Name is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Secondary And MicroNutrients</Label>
                <Input
                  type="text"
                  name="secondaryAndMicroNutrients"
                  placeholder="Name"
                  className={submitted && !this.state.secondaryAndMicroNutrients ? "is-invalid" : ""}
                  value={this.state.secondaryAndMicroNutrients}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.secondaryAndMicroNutrients && (
                  <div className="invalid-feedback-custom">
                   SecondaryAndMicroNutrients is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className={"select2-container"}>
                <Label>Type</Label>
                <Select
                  name="type"
                  value={this.state.type}
                  onChange={this.handleSelectChange}
                  options={typeOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
              <FormGroup className={"select2-container"}>
                <Label>Physical Property</Label>
                <Select
                  name="physicalPropertyId"
                  value={this.state.physicalPropertyId}
                  onChange={this.handleSelectChange}
                  options={this.state.physicalPropertyOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Primary Nutrients</Label>
                <Input
                  type="text"
                  name="primaryNutrients"
                  placeholder="Primary Nutrients"
                  className={submitted && !this.state.primaryNutrients ? "is-invalid" : ""}
                  value={this.state.primaryNutrients}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.primaryNutrients && (
                  <div className="invalid-feedback-custom">
                    primaryNutrients is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className={"select2-container"}>
                <Label>Use For On</Label>
                <Select
                  name="useForOnId"
                  value={this.state.useForOnId}
                  onChange={this.handleSelectChange}
                  options={this.state.useForOnOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
             
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  rows="4"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default ModalForm;
