import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_COUNTRIES,
  ADD_NEW_COUNTRY,
  GET_COUNTRY_BY_ID,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCountriesSuccess,
  getCountryByIdSuccess,
  getCountriesFail,
} from "./actions";

const getCountriesRequest = query =>
  get("/api/Country/paging", { params: query });
const addNewCountryRequest = item => post("/api/Country", item);
const getCountryByIdRequest = id => get(`/api/Country/${id}`);
const updateCountryRequest = item => update("/api/Country", item);
const deleteCountryRequest = id => del(`/api/Country/${id}`);

function* fetchCountries({ payload }) {
  try {
    const response = yield call(getCountriesRequest, payload);
    yield put(getCountriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCountriesFail(error));
  }
}

function* addNewCountry({ payload }) {
  try {
    yield call(addNewCountryRequest, payload);
    const response = yield call(getCountriesRequest, {});
    yield put(getCountriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCountriesFail(error));
  }
}

function* updateCountry({ payload }) {
  try {
    yield call(updateCountryRequest, payload);
    const response = yield call(getCountriesRequest, {});
    yield put(getCountriesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCountriesFail(error));
  }
}

function* getCountryById({ payload }) {
  try {
    var response = yield call(getCountryByIdRequest, payload);
    yield put(getCountryByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCountriesFail(error));
  }
}

function* deleteCountry({ payload }) {
  try {
    yield call(deleteCountryRequest, payload);
    const response = yield call(getCountriesRequest, {});
    yield put(getCountriesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCountriesFail(error));
  }
}

function* countrySaga() {
  yield takeEvery(GET_COUNTRIES, fetchCountries);
  yield takeEvery(ADD_NEW_COUNTRY, addNewCountry);
  yield takeEvery(GET_COUNTRY_BY_ID, getCountryById);
  yield takeEvery(UPDATE_COUNTRY, updateCountry);
  yield takeEvery(DELETE_COUNTRY, deleteCountry);
}

export default countrySaga;
