// BANNED_PESTICIDE
export const GET_BANNED_PESTICIDES = "GET_BANNED_PESTICIDES";
export const GET_BANNED_PESTICIDES_SUCCESS = "GET_BANNED_PESTICIDES_SUCCESS";
export const GET_BANNED_PESTICIDES_FAIL = "GET_BANNED_PESTICIDES_FAIL";

export const GET_BANNED_PESTICIDE_BY_ID = "GET_BANNED_PESTICIDE_BY_ID";
export const GET_BANNED_PESTICIDE_BY_ID_SUCCESS =
  "GET_BANNED_PESTICIDE_BY_ID_SUCCESS";

export const ADD_NEW_BANNED_PESTICIDE = "ADD_NEW_BANNED_PESTICIDE";

export const UPDATE_BANNED_PESTICIDE = "UPDATE_BANNED_PESTICIDE";

export const DELETE_BANNED_PESTICIDE = "DELETE_BANNED_PESTICIDE";

export const BANNED_PESTICIDE_CLEAR_ERROR = "BANNED_PESTICIDE_CLEAR_ERROR";
