// PESTICIDE_REGISTRATION
export const GET_PESTICIDE_REGISTRATIONS = "GET_PESTICIDE_REGISTRATIONS";
export const GET_PESTICIDE_REGISTRATIONS_SUCCESS =
  "GET_PESTICIDE_REGISTRATIONS_SUCCESS";
export const GET_PESTICIDE_REGISTRATIONS_FAIL =
  "GET_PESTICIDE_REGISTRATIONS_FAIL";

export const GET_PESTICIDE_REGISTRATION_BY_ID =
  "GET_PESTICIDE_REGISTRATION_BY_ID";
export const GET_PESTICIDE_REGISTRATION_BY_ID_SUCCESS =
  "GET_PESTICIDE_REGISTRATION_BY_ID_SUCCESS";

export const ADD_NEW_PESTICIDE_REGISTRATION = "ADD_NEW_PESTICIDE_REGISTRATION";

export const UPDATE_PESTICIDE_REGISTRATION = "UPDATE_PESTICIDE_REGISTRATION";

export const DELETE_PESTICIDE_REGISTRATION = "DELETE_PESTICIDE_REGISTRATION";

export const PESTICIDE_REGISTRATION_CLEAR_ERROR =
  "PESTICIDE_REGISTRATION_CLEAR_ERROR";
