import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_BANNED_PESTICIDES,
  ADD_NEW_BANNED_PESTICIDE,
  GET_BANNED_PESTICIDE_BY_ID,
  UPDATE_BANNED_PESTICIDE,
  DELETE_BANNED_PESTICIDE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getBannedPesticidesSuccess,
  getBannedPesticideByIdSuccess,
  getBannedPesticidesFail,
} from "./actions";

const getBannedPesticidesRequest = query =>
  get("/api/BannedPesticide/paging", { params: query });
const addNewBannedPesticideRequest = item => post("/api/BannedPesticide", item);
const getBannedPesticideByIdRequest = id => get(`/api/BannedPesticide/${id}`);
const updateBannedPesticideRequest = item => update("/api/BannedPesticide", item);
const deleteBannedPesticideRequest = id => del(`/api/BannedPesticide/${id}`);

function* fetchBannedPesticides({ payload }) {
  try {
    const response = yield call(getBannedPesticidesRequest, payload);
    yield put(getBannedPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBannedPesticidesFail(error));
  }
}

function* addNewBannedPesticide({ payload }) {
  try {
    yield call(addNewBannedPesticideRequest, payload);
    const response = yield call(getBannedPesticidesRequest, {});
    yield put(getBannedPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBannedPesticidesFail(error));
  }
}

function* updateBannedPesticide({ payload }) {
  try {
    yield call(updateBannedPesticideRequest, payload);
    const response = yield call(getBannedPesticidesRequest, {});
    yield put(getBannedPesticidesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBannedPesticidesFail(error));
  }
}

function* getBannedPesticideById({ payload }) {
  try {
    var response = yield call(getBannedPesticideByIdRequest, payload);
    yield put(getBannedPesticideByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBannedPesticidesFail(error));
  }
}

function* deleteBannedPesticide({ payload }) {
  try {
    yield call(deleteBannedPesticideRequest, payload);
    const response = yield call(getBannedPesticidesRequest, {});
    yield put(getBannedPesticidesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getBannedPesticidesFail(error));
  }
}

function* bannedPesticideSaga() {
  yield takeEvery(GET_BANNED_PESTICIDES, fetchBannedPesticides);
  yield takeEvery(ADD_NEW_BANNED_PESTICIDE, addNewBannedPesticide);
  yield takeEvery(GET_BANNED_PESTICIDE_BY_ID, getBannedPesticideById);
  yield takeEvery(UPDATE_BANNED_PESTICIDE, updateBannedPesticide);
  yield takeEvery(DELETE_BANNED_PESTICIDE, deleteBannedPesticide);
}

export default bannedPesticideSaga;
