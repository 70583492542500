// Company
import {
    GET_COMPANIES,
    GET_COMPANIES_SUCCESS,
    GET_COMPANIES_FAIL,
    GET_COMPANY_BY_ID_SUCCESS,  
  } from "./actionTypes.js";
  const initState = {
    items: [],
    item: null,
    error: null,
    itemCount: 0,
    pageCount: 0,
    loading: false,
  }; 
  
  const Company = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_COMPANIES:
        return {
          ...state,
          loading: true,
        };
      case GET_COMPANIES_SUCCESS:
        const { itemCount, pageCount, data } = payload;
        return {
          ...state,
          itemCount,
          pageCount,
          items: data,
          loading: false,
        };
      case GET_COMPANY_BY_ID_SUCCESS:
        return {
          ...state,
          item: payload,
        };
      case GET_COMPANIES_FAIL:
        return {
          ...state,
          error: payload,
        };
      default:
        return state;
    }
  };
  
  export default Company;
  