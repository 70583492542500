import {
  GET_FERTILIZERS,
  GET_FERTILIZERS_SUCCESS,
  GET_FERTILIZERS_FAIL,
  GET_FERTILIZER_BY_ID,
  GET_FERTILIZER_BY_ID_SUCCESS,
  ADD_NEW_FERTILIZER,
  UPDATE_FERTILIZER,
  DELETE_FERTILIZER,
  FERTILIZER_CLEAR_ERROR,
} from "./actionTypes";

export const getFertilizers = (query) => ({
  type: GET_FERTILIZERS,
  payload: query,
});

export const getFertilizersSuccess = (banners) => ({
  type: GET_FERTILIZERS_SUCCESS,
  payload: banners,
});

export const getFertilizersFail = (error) => ({
  type: GET_FERTILIZERS_FAIL,
  payload: error,
});

export const getFertilizerById = (id) => ({
  type: GET_FERTILIZER_BY_ID,
  payload: id,
});

export const getFertilizerByIdSuccess = (banner) => ({
  type: GET_FERTILIZER_BY_ID_SUCCESS,
  payload: banner,
});

export const getFertilizerByIdFail = (error) => ({
  type: GET_FERTILIZER_BY_ID_FAIL,
  payload: error,
});

export const addNewFertilizer = (banner) => ({
  type: ADD_NEW_FERTILIZER,
  payload: banner,
});

export const updateFertilizer = (banner) => ({
  type: UPDATE_FERTILIZER,
  payload: banner,
});

export const deleteFertilizer = (banner) => ({
  type: DELETE_FERTILIZER,
  payload: banner,
});

export const clearFertilizerError = () => ({
  type: FERTILIZER_CLEAR_ERROR,
});
