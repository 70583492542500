// Fertilizer
export const GET_FERTILIZERS = "GET_FERTILIZERS";
export const GET_FERTILIZERS_SUCCESS = "GET_FERTILIZERS_SUCCESS";
export const GET_FERTILIZERS_FAIL = "GET_FERTILIZERS_FAIL";

export const GET_FERTILIZER_BY_ID = "GET_FERTILIZER_BY_ID";
export const GET_FERTILIZER_BY_ID_SUCCESS = "GET_FERTILIZER_BY_ID_SUCCESS";

export const ADD_NEW_FERTILIZER = "ADD_NEW_FERTILIZER";

export const UPDATE_FERTILIZER = "UPDATE_FERTILIZER";

export const DELETE_FERTILIZER = "DELETE_FERTILIZER";

export const FERTILIZER_CLEAR_ERROR = "FERTILIZER_CLEAR_ERROR";
