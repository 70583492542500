import {
  GET_CODE_TYPES,
  GET_CODE_TYPES_SUCCESS,
  GET_CODE_TYPES_FAIL,
  GET_CODE_TYPE_BY_ID,
  GET_CODE_TYPE_BY_ID_SUCCESS,
  ADD_NEW_CODE_TYPE,
  UPDATE_CODE_TYPE,
  DELETE_CODE_TYPE,
  CODE_TYPE_CLEAR_ERROR,
} from "./actionTypes";

export const getCodeTypes = (query) => ({
  type: GET_CODE_TYPES,
  payload: query,
});

export const getCodeTypesSuccess = (banners) => ({
  type: GET_CODE_TYPES_SUCCESS,
  payload: banners,
});

export const getCodeTypesFail = (error) => ({
  type: GET_CODE_TYPES_FAIL,
  payload: error,
});

export const getCodeTypeById = (id) => ({
  type: GET_CODE_TYPE_BY_ID,
  payload: id,
});

export const getCodeTypeByIdSuccess = (banner) => ({
  type: GET_CODE_TYPE_BY_ID_SUCCESS,
  payload: banner,
});

export const getCodeTypeByIdFail = (error) => ({
  type: GET_CODE_TYPE_BY_ID_FAIL,
  payload: error,
});

export const addNewCodeType = (banner) => ({
  type: ADD_NEW_CODE_TYPE,
  payload: banner,
});

export const updateCodeType = (banner) => ({
  type: UPDATE_CODE_TYPE,
  payload: banner,
});

export const deleteCodeType = (banner) => ({
  type: DELETE_CODE_TYPE,
  payload: banner,
});

export const clearCodeTypeError = () => ({
  type: CODE_TYPE_CLEAR_ERROR,
});
