import React, { Component } from 'react'
import { Card, CardBody } from 'reactstrap'
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { codeTypeService } from "services/code-type-service";

export class Form extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        id: 0,
        description: "",
        companyId: 0,
        importFrom:"",
        startDate: "",
        endDate: "",
        status: "",
        company: "",
        submitted: false,
  
        companyOptions: []
      };
    }

  render() {
    const { submitted } = this.state;
    return (
        <>
        
      <Card className='mb-2'>
        <CardBody>
        <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01"></Label>
                <Input
                  type="text"
                  name="status"
                  placeholder="Status"
                  className={submitted && !this.state.status ? "is-invalid" : ""}
                  value={this.state.status}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.status && (
                  <div className="invalid-feedback-custom">
                    status is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Start Date</Label>
                <Input
                  type="text"
                  name="startDate"
                  placeholder="Start Date"
                  className={submitted && !this.state.startDate ? "is-invalid" : ""}
                  value={this.state.startDate}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.startDate && (
                  <div className="invalid-feedback-custom">
                    startDate is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">End Date</Label>
                <Input
                  type="text"
                  name="endDate"
                  placeholder="End Date"
                  className={submitted && !this.state.endDate ? "is-invalid" : ""}
                  value={this.state.endDate}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.endDate && (
                  <div className="invalid-feedback-custom">
                    startDate is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Import From</Label>
                <Input
                  type="text"
                  name="importFrom"
                  placeholder="Import From"
                  className={submitted && !this.state.importFrom ? "is-invalid" : ""}
                  value={this.state.importFrom}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.importFrom && (
                  <div className="invalid-feedback-custom">
                    importFrom is required
                  </div>
                )}
              </FormGroup>
            
              <FormGroup className={"select2-container"}>
                <Label>Company Id</Label>
                <Select
                  name="companyId"
                  value={this.state.companyId}
                  onChange={this.handleSelectChange}
                  options={this.state.companyOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
          
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  rows="4"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
        <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>

        </CardBody>
      </Card>
      </>
    )
  }
}

export default Form