import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_PERMISSIONS,
  ADD_NEW_PERMISSION,
  GET_PERMISSION_BY_ID,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getPermissionsSuccess,
  getPermissionByIdSuccess,
  getPermissionsFail,
} from "./actions";

const getPermissionsRequest = (query) =>
  get("/api/Permission/paging", { params: query });
const addNewPermissionRequest = (item) => post("/api/Permission", item);
const getPermissionByIdRequest = (id) => get(`/api/Permission/${id}`);
const updatePermissionRequest = (item) => update("/api/Permission", item);
const deletePermissionRequest = (id) => del(`/api/Permission/${id}`);

function* fetchPermissions({ payload }) {
  try {
    const response = yield call(getPermissionsRequest, payload);
    yield put(getPermissionsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPermissionsFail(error));
  }
}

function* addNewPermission({ payload }) {
  try {
    yield call(addNewPermissionRequest, payload);
    const response = yield call(getPermissionsRequest, {});
    yield put(getPermissionsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPermissionsFail(error));
  }
}

function* updatePermission({ payload }) {
  try {
    yield call(updatePermissionRequest, payload);
    const response = yield call(getPermissionsRequest, {});
    yield put(getPermissionsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPermissionsFail(error));
  }
}

function* getPermissionById({ payload }) {
  try {
    var response = yield call(getPermissionByIdRequest, payload);
    yield put(getPermissionByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPermissionsFail(error));
  }
}

function* deletePermission({ payload }) {
  try {
    yield call(deletePermissionRequest, payload);
    const response = yield call(getPermissionsRequest, {});
    yield put(getPermissionsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getPermissionsFail(error));
  }
}

function* permissionSaga() {
  yield takeEvery(GET_PERMISSIONS, fetchPermissions);
  yield takeEvery(ADD_NEW_PERMISSION, addNewPermission);
  yield takeEvery(GET_PERMISSION_BY_ID, getPermissionById);
  yield takeEvery(UPDATE_PERMISSION, updatePermission);
  yield takeEvery(DELETE_PERMISSION, deletePermission);
}

export default permissionSaga;
