// RESTRICTED_PESTICIDE
export const GET_RESTRICTED_PESTICIDES = "GET_RESTRICTED_PESTICIDES";
export const GET_RESTRICTED_PESTICIDES_SUCCESS =
  "GET_RESTRICTED_PESTICIDES_SUCCESS";
export const GET_RESTRICTED_PESTICIDES_FAIL = "GET_RESTRICTED_PESTICIDES_FAIL";

export const GET_RESTRICTED_PESTICIDE_BY_ID = "GET_RESTRICTED_PESTICIDE_BY_ID";
export const GET_RESTRICTED_PESTICIDE_BY_ID_SUCCESS =
  "GET_RESTRICTED_PESTICIDE_BY_ID_SUCCESS";

export const ADD_NEW_RESTRICTED_PESTICIDE = "ADD_NEW_RESTRICTED_PESTICIDE";

export const UPDATE_RESTRICTED_PESTICIDE = "UPDATE_RESTRICTED_PESTICIDE";

export const DELETE_RESTRICTED_PESTICIDE = "DELETE_RESTRICTED_PESTICIDE";

export const RESTRICTED_PESTICIDE_CLEAR_ERROR =
  "RESTRICTED_PESTICIDE_CLEAR_ERROR";
