import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { codeTypeService } from "services/code-type-service";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      description: "",
      companyId: 0,
      importFrom:"",
      startDate: "",
      endDate: "",
      status: "",
      company: "",
      submitted: false,

      companyOptions: []
    };
  }

  componentDidMount(){

       codeTypeService.getByTypeIdRequest(4).then(data =>{
      if(data){
         this.setState({
          companyOptions: data.map(a =>{
            return {
              key: a.id,
              value: a.id,
              label: a.name
            }
          })
         })
      }
    })
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, description, companyId, importFrom,  startDate,  endDate, status, company } = this.state;
    this.setState({
      submitted: true,
    });

    const item = {
      id,
      description,
      companyId,
      importFrom,
      companyId: companyId?.value ?? null,
      startDate,
      endDate,
      status,
      company,
    };

    var isValid = item.name  && item.companyId;

    if (isValid) {
      this.props.onSubmit(item);
    }
  };

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      console.log(item);
      this.setState({
        ...item,
        companyId: {key: item.companyId, value: item.companyId, label: item.company },
        description: item.description ?? "",
      });
    } else {
      this.setState({
        id: 0,
        description: "",
        importFrom: "",
        startDate: "",
        endDate: "",
        companyId: 0,
        company: "",
        status: "",
        submitted: false,
      });
    }
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal size="md" onOpened={this.onOpened} isOpen={isOpen} toggle={toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={submitted && !this.state.name ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Name is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Start Date</Label>
                <Input
                  type="text"
                  name="startDate"
                  placeholder="Start Date"
                  className={submitted && !this.state.startDate ? "is-invalid" : ""}
                  value={this.state.startDate}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.startDate && (
                  <div className="invalid-feedback-custom">
                    startDate is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">End Date</Label>
                <Input
                  type="text"
                  name="endDate"
                  placeholder="End Date"
                  className={submitted && !this.state.endDate ? "is-invalid" : ""}
                  value={this.state.endDate}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.endDate && (
                  <div className="invalid-feedback-custom">
                    startDate is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Import From</Label>
                <Input
                  type="text"
                  name="importFrom"
                  placeholder="Import From"
                  className={submitted && !this.state.importFrom ? "is-invalid" : ""}
                  value={this.state.importFrom}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.importFrom && (
                  <div className="invalid-feedback-custom">
                    importFrom is required
                  </div>
                )}
              </FormGroup>
            
              <FormGroup className={"select2-container"}>
                <Label>Company Id</Label>
                <Select
                  name="companyId"
                  value={this.state.companyId}
                  onChange={this.handleSelectChange}
                  options={this.state.companyOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
          
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  rows="4"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default ModalForm;
