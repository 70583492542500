import {
  GET_BANNED_PESTICIDES,
  GET_BANNED_PESTICIDES_SUCCESS,
  GET_BANNED_PESTICIDES_FAIL,
  GET_BANNED_PESTICIDE_BY_ID,
  GET_BANNED_PESTICIDE_BY_ID_SUCCESS,
  ADD_NEW_BANNED_PESTICIDE,
  UPDATE_BANNED_PESTICIDE,
  DELETE_BANNED_PESTICIDE,
  BANNED_PESTICIDE_CLEAR_ERROR,
} from "./actionTypes";

export const getBannedPesticides = (query) => ({
  type: GET_BANNED_PESTICIDES,
  payload: query,
});

export const getBannedPesticidesSuccess = (banners) => ({
  type: GET_BANNED_PESTICIDES_SUCCESS,
  payload: banners,
});

export const getBannedPesticidesFail = (error) => ({
  type: GET_BANNED_PESTICIDES_FAIL,
  payload: error,
});

export const getBannedPesticideById = (id) => ({
  type: GET_BANNED_PESTICIDE_BY_ID,
  payload: id,
});

export const getBannedPesticideByIdSuccess = (banner) => ({
  type: GET_BANNED_PESTICIDE_BY_ID_SUCCESS,
  payload: banner,
});

export const getBannedPesticideByIdFail = (error) => ({
  type: GET_BANNED_PESTICIDE_BY_ID_FAIL,
  payload: error,
});

export const addNewBannedPesticide = (banner) => ({
  type: ADD_NEW_BANNED_PESTICIDE,
  payload: banner,
});

export const updateBannedPesticide = (banner) => ({
  type: UPDATE_BANNED_PESTICIDE,
  payload: banner,
});

export const deleteBannedPesticide = (banner) => ({
  type: DELETE_BANNED_PESTICIDE,
  payload: banner,
});

export const clearBannedPesticideError = () => ({
  type: BANNED_PESTICIDE_CLEAR_ERROR,
});
