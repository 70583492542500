import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_COMPANIES,
  ADD_NEW_COMPANY, 
  GET_COMPANY_BY_ID,
  UPDATE_COMPANY,
  DELETE_COMPANY,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getCompaniesSuccess, 
  getCompanyByIdSuccess,
  getCompaniesFail,
} from "./actions";

const getCompaniesRequest = query =>
  get("/api/Company/paging", { params: query });
const addNewCompanyRequest = item => post("/api/Company", item);
const getCompanyByIdRequest = id => get(`/api/Company/${id}`);
const updateCompanyRequest = item => update("/api/Company", item);
const deleteCompanyRequest = id => del(`/api/Company/${id}`);

function* fetchCompanies({ payload }) {
  try {
    const response = yield call(getCompaniesRequest, payload);
    yield put(getCompaniesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCompaniesFail(error));
  }
}

function* addNewCompany({ payload }) {
  try {
    yield call(addNewCompanyRequest, payload);
    const response = yield call(getCompaniesRequest, {});
    yield put(getCompaniesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCompaniesFail(error));
  }
}

function* updateCompany({ payload }) {
  try {
    yield call(updateCompanyRequest, payload);
    const response = yield call(getCompaniesRequest, {});
    yield put(getCompaniesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCompaniesFail(error));
  }
}

function* getCompanyById({ payload }) {
  try {
    var response = yield call(getCompanyByIdRequest, payload);
    yield put(getCompanyByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCompaniesFail(error));
  }
}

function* deleteCompany({ payload }) {
  try {
    yield call(deleteCompanyRequest, payload);
    const response = yield call(getCompaniesRequest, {});
    yield put(getCompaniesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getCompaniesFail(error));
  }
}

function*  companySaga() {
  yield takeEvery(GET_COMPANIES, fetchCompanies);
  yield takeEvery(ADD_NEW_COMPANY, addNewCompany);
  yield takeEvery(GET_COMPANY_BY_ID, getCompanyById);
  yield takeEvery(UPDATE_COMPANY, updateCompany);
  yield takeEvery(DELETE_COMPANY, deleteCompany);
}

export default  companySaga;
