import { call, put, takeEvery } from "redux-saga/effects";
import {
  GET_LANGUAGES,
  ADD_NEW_LANGUAGE,
  GET_LANGUAGE_BY_ID,
  UPDATE_LANGUAGE,
  DELETE_LANGUAGE,
} from "./actionTypes";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getLanguagesSuccess,
  getLanguageByIdSuccess,
  getLanguagesFail,
} from "./actions";

const getLanguagesRequest = (query) =>
  get("/api/Language/paging", { params: query });
const addNewLanguageRequest = (item) => post("/api/Language", item);
const getLanguageByIdRequest = (id) => get(`/api/Language/${id}`);
const updateLanguageRequest = (item) => update("/api/Language", item);
const deleteLanguageRequest = (id) => del(`/api/Language/${id}`);

function* fetchLanguages({ payload }) {
  try {
    const response = yield call(getLanguagesRequest, payload);
    yield put(getLanguagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getLanguagesFail(error));
  }
}

function* addNewLanguage({ payload }) {
  try {
    yield call(addNewLanguageRequest, payload);
    const response = yield call(getLanguagesRequest, {});
    yield put(getLanguagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getLanguagesFail(error));
  }
}

function* updateLanguage({ payload }) {
  try {
    yield call(updateLanguageRequest, payload);
    const response = yield call(getLanguagesRequest, {});
    yield put(getLanguagesSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getLanguagesFail(error));
  }
}

function* getLanguageById({ payload }) {
  try {
    var response = yield call(getLanguageByIdRequest, payload);
    yield put(getLanguageByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getLanguagesFail(error));
  }
}

function* deleteLanguage({ payload }) {
  try {
    yield call(deleteLanguageRequest, payload);
    const response = yield call(getLanguagesRequest, {});
    yield put(getLanguagesSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.title ?? "Something has gone wrong.";
    yield put(getLanguagesFail(error));
  }
}

function* languageSaga() {
  yield takeEvery(GET_LANGUAGES, fetchLanguages);
  yield takeEvery(ADD_NEW_LANGUAGE, addNewLanguage);
  yield takeEvery(GET_LANGUAGE_BY_ID, getLanguageById);
  yield takeEvery(UPDATE_LANGUAGE, updateLanguage);
  yield takeEvery(DELETE_LANGUAGE, deleteLanguage);
}

export default languageSaga;
