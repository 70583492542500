import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";

import { 
  getPesticideRegistrations,  
  addNewPesticideRegistration,
  updatePesticideRegistration,
  getPesticideRegistrationById,
  deletePesticideRegistration,
} from "store/actions";

import PesticideRegistrationTable from "containers/pesticide-registration/PesticideRegistrationTable";
import ModalForm from "containers/pesticide-registration/ModalForm";


export class PesticideRegistrationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      currentPage: 1,
      pageSize: 10,
      modalAddIsOpen: false,
      modalEditIsOpen: false,
      modalConfirmDeleteIsOpen: false,
    };
  }

  toggleModalAdd = () => {
    this.setState(prevState => ({
      modalAddIsOpen: !prevState.modalAddIsOpen,
    }));
  };

  toggleModalEdit = () => {
    this.setState(prevState => ({
      modalEditIsOpen: !prevState.modalEditIsOpen,
    }));
  };

  toggleModalConfirmDelete = () => {
    this.setState(prevState => ({
      modalConfirmDeleteIsOpen: !prevState.modalConfirmDeleteIsOpen,
    }));
  };

  componentDidMount() {
    this.props.getPesticideRegistrations({ page: 1, pageSize: 10, filter: "" });

    // toastr.options = {
    //   timeOut: 5000,
    //   closeButton: true,
    // };
    // toastr.error("Bad Request", "Error");
  }

  handleOnEdit = id => {
    const {history} = this.props;
    history.push(`/pesticide-registration/${id}`)
    // this.props.getPesticideRegistrationById(id);
    // this.setState({
    //   id,
    //   modalEditIsOpen: true,
    // });
  };

  handleConfirmDelete = id => {
    this.setState({
      id,
      modalConfirmDeleteIsOpen: true,
    });
  };

  handleOnAddNew = () => {
    const {history} = this.props;
    history.push('/pesticide-registration/add-new')
    // this.setState({
    //   modalAddIsOpen: true,
    // });
  };

  handleOnSearch = e => {
    const { value } = e.target;
    this.props.getPesticideRegistrations({ filter: value ?? "" });
  };

  handleSubmit = item => {
    this.props.addNewPesticideRegistration(item);
    this.setState({
      modalAddIsOpen: false,
    });
  };

  handleUpdate = item => {
    this.props.updatePesticideRegistration(item);
    this.setState({
      modalEditIsOpen: false,
    });
  };

  handleDelete = () => {
    const { id } = this.state;
    this.props.deletePesticideRegistration(id);
    this.setState({ modalConfirmDeleteIsOpen: false });
  };

  componentDidUpdate() {
    const { error } = this.props;

    if (error) {
      toastr.options = {
        timeOut: 5000,
      };
      toastr.error(error, "Error");

      // this.props.clearCategoryError();
    }
  }

  handleOnPageChange = page => {
    const { pageSize } = this.state;
    this.props.getPesticideRegistrations({
      page,
      pageSize,
    });
    this.setState({
      currentPage: page,
    });
  };

  handleOnPageSizeChange = pageSize => {
    this.props.getPesticideRegistrations({
      page: 1,
      pageSize,
    });

    this.setState({
      pageSize,
    });
  };

  render() {
    const { pageSize, currentPage } = this.state;
    const { items, item, itemCount, pageCount, loading } = this.props;
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Dashboard | Skote - React Admin & Dashboard Template</title>
          </MetaTags>
          <Container fluid>

            <Breadcrumbs title="Settings" breadcrumbItem="pesticide registration Testing" />
            <Row>
              <Col lg="12">
                {items && (
                  <PesticideRegistrationTable
                    items={items}
                    itemCount={itemCount}
                    currentPage={currentPage}
                    totalPage={pageCount}
                    loading={loading}
                    defaultPageSize={pageSize}
                    onSearch={this.handleOnSearch}
                    onAddNew={this.handleOnAddNew}
                    onEdit={this.handleOnEdit}
                    onConfirmDelete={this.handleConfirmDelete}
                    onChangePage={this.handleOnPageChange}
                    onPageSizeChange={this.handleOnPageSizeChange}
                  />
                )}
                <ModalForm
                  title={"Pesticide Registration"}
                  isOpen={this.state.modalAddIsOpen}
                  toggle={this.toggleModalAdd}
                  onSubmit={this.handleSubmit}
                />

                {item && (
                  <ModalForm
                    title={"Edit Pesticide_Registration"}
                    item={item}
                    isOpen={this.state.modalEditIsOpen}
                    toggle={this.toggleModalEdit}
                    onSubmit={this.handleUpdate}
                  />
                )}

                <ConfirmDeleteModal
                  title="Confirm Delete"
                  isOpen={this.state.modalConfirmDeleteIsOpen}
                  toggle={this.toggleModalConfirmDelete}
                  onSubmit={this.handleDelete}
                />
              </Col>
            </Row>
          </Container>
          <h5></h5>
        </div>
      </React.Fragment>
    );
  }
}

PesticideRegistrationList.propTypes = {
  history: PropTypes.object,
  items: PropTypes.array,
  item: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  itemCount: PropTypes.number,
  pageCount: PropTypes.number,
  getPesticideRegistrations: PropTypes.func,
  addNewPesticideRegistration: PropTypes.func,
  updatePesticideRegistration: PropTypes.func,
  getPesticideRegistrationById: PropTypes.func,
  deletePesticideRegistration: PropTypes.func,
  clearCategoryError: PropTypes.func,
};

const mapStateToProps = ({ pesticideRegistration }) => {
  return pesticideRegistration;
};

export default withRouter(
  connect(mapStateToProps, {
    getPesticideRegistrations,
    addNewPesticideRegistration,
    updatePesticideRegistration,
    getPesticideRegistrationById, 
    deletePesticideRegistration
  })(PesticideRegistrationList)
);