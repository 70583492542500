import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Col, FormGroup, Modal, Label, Row, Input } from "reactstrap";
import { codeTypeService } from "services/code-type-service";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      name: "",
      casrn: "",
      use:"",
      toxicityClassId: 0,
      toxicityClass: "",
      description: "",
      submitted: false,

      toxicityClassOptions: []
    };
  }

  componentDidMount(){

       codeTypeService.getByTypeIdRequest(4).then(data =>{
      if(data){
         this.setState({
          toxicityClassOptions: data.map(a =>{
            return {
              key: a.id,
              value: a.id,
              label: a.name
            }
          })
         })
      }
    })
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => {
    const { id, name, casrn, use,  toxicityClassId,  description } = this.state;
    this.setState({
      submitted: true,
    });

    const item = {
      id,
      name,
      casrn,
      use,
      toxicityClassId: toxicityClassId?.value ?? null,
      description,
    };

    var isValid = item.name  && item.toxicityClassId;

    if (isValid) {
      this.props.onSubmit(item);
    }
  };

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      console.log(item);
      this.setState({
        ...item,
       // use: {key: item.use, value: item.use, label: item.use },
        toxicityClassId: {key: item.toxicityClassId, value: item.toxicityClassId, label: item.toxicityClass },
        description: item.description ?? "",
      });
    } else {
      this.setState({
        id: 0,
        name: "",
        casrn: "",
        use: "",
        toxicityClassId: 0,
        toxicityClass: "",
        description: "",
        submitted: false,
      });
    }
  };

  handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;
    this.setState({
      [name]: valueType,
    });
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal size="md" onOpened={this.onOpened} isOpen={isOpen} toggle={toggle}>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={submitted && !this.state.name ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Name is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Casrn</Label>
                <Input
                  type="text"
                  name="casrn"
                  placeholder="Casrn"
                  className={submitted && !this.state.casrn ? "is-invalid" : ""}
                  value={this.state.casrn}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.casrn && (
                  <div className="invalid-feedback-custom">
                    Casrn is required
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Use</Label>
                <Input
                  type="text"
                  name="use"
                  placeholder="Use"
                  className={submitted && !this.state.use ? "is-invalid" : ""}
                  value={this.state.use}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.use && (
                  <div className="invalid-feedback-custom">
                    Use is required
                  </div>
                )}
              </FormGroup>
            
              <FormGroup className={"select2-container"}>
                <Label>Toxicity Class</Label>
                <Select
                  name="toxicityClassId"
                  value={this.state.toxicityClassId}
                  onChange={this.handleSelectChange}
                  options={this.state.toxicityClassOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
          
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  rows="4"
                  placeholder="Description"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}
ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
  userId: PropTypes.string,
  contact: PropTypes.string,
};

export default ModalForm;
