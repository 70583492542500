import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_COUNTRY_BY_ID,
  GET_COUNTRY_BY_ID_SUCCESS,
  ADD_NEW_COUNTRY,
  UPDATE_COUNTRY,
  DELETE_COUNTRY,
  COUNTRY_CLEAR_ERROR,
} from "./actionTypes";

export const getCountries = query => ({
  type: GET_COUNTRIES,
  payload: query,
});

export const getCountriesSuccess = banners => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: banners,
});
  
export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
});

export const getCountryById = id => ({
  type: GET_COUNTRY_BY_ID,
  payload: id,
});

export const getCountryByIdSuccess = banner => ({
  type: GET_COUNTRY_BY_ID_SUCCESS,
  payload: banner,
});

export const getCountryByIdFail = error => ({
  type: GET_COUNTRY_BY_ID_FAIL,
  payload: error,
});

export const addNewCountry = banner => ({
  type: ADD_NEW_COUNTRY,
  payload: banner,
});

export const updateCountry = banner => ({
  type: UPDATE_COUNTRY,
  payload: banner,
});

export const deleteCountry = banner => ({
  type: DELETE_COUNTRY,
  payload: banner,
});

export const clearCountryError = () => ({
  type: COUNTRY_CLEAR_ERROR,
});
