// BORDER_CROSSING
export const GET_BORDER_CROSSINGS = "BORDER_CROSSINGS";
export const GET_BORDER_CROSSINGS_SUCCESS = "BORDER_CROSSINGS_SUCCESS";
export const GET_BORDER_CROSSINGS_FAIL = "BORDER_CROSSINGS_FAIL";

export const GET_BORDER_CROSSING_BY_ID = "GET_BORDER_CROSSING_BY_ID";
export const GET_BORDER_CROSSING_BY_ID_SUCCESS = "GET_BORDER_CROSSING_BY_ID_SUCCESS";

export const ADD_NEW_BORDER_CROSSING = "ADD_NEW_BORDER_CROSSING";

export const UPDATE_BORDER_CROSSING = "UPDATE_BORDER_CROSSING";

export const DELETE_BORDER_CROSSING = "DELETE_BORDER_CROSSING";

export const BORDER_CROSSING_CLEAR_ERROR = "BORDER_CROSSING_CLEAR_ERROR";
